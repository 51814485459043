/* AUTO FADE SLIDER */
.fader {
  height: 80vh;
  max-height: 80vh;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}

.fader__slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.fader img {
  background-color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

/* LIVE MATCH AUTO FADE SLIDER */
.live-match-fader {
  height: 25vh;
  max-height: 25vh;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}

@media (min-width: 640px) {
  .live-match-fader {
    height: 40vh;
    max-height: 40vh;
    object-fit: cover;
    position: relative;
    overflow: hidden;
  }
}

@media (min-width: 1536px) {
  .live-match-fader {
    height: 50vh;
    max-height: 50vh;
    object-fit: cover;
    position: relative;
    overflow: hidden;
  }
}

.live_match_fader__slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.live-match-fader img {
  background-color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

/* GALLERY SLIDER */

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 80vh;
  max-height: 80vh;
}

.thumbnail .keen-slider__slide {
  font-size: 30px;
  margin-top: 10px;
  height: 100px;
}
.thumbnail .keen-slider__slide {
  cursor: pointer;
}
.thumbnail .keen-slider__slide.active {
  border: 2px dashed black;
}
