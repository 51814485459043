/* MARKDOWN CUSTOM STYLES */

.markdown h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 24px;
  margin-bottom: 24px;

  font-weight: bold;
}

.markdown h1 {
  font-size: 1.5em; /* 24px --> 24 ÷ 16 = 1.5 */
  line-height: 1em; /* 24px --> 24 ÷ 24 = 1 */
}

.markdown h2 {
  font-size: 1.375em; /* 22px --> 22 ÷ 16 = 1.375 */
  line-height: 1.0909em; /* 24px --> 24 ÷ 22 = 1.090909(09) */
}

.markdown h3 {
  font-size: 1.25em; /* 20px --> 20 ÷ 16 = 1.25 */
  line-height: 1.2em; /* 24px --> 24 ÷ 20 = 1.2 */
}

.markdown h4 {
  font-size: 1.125em; /* 18px --> 18 ÷ 16 = 1.125 */
  line-height: 1.333em; /* 24px --> 24 ÷ 18 = 1.3333333(3) */
}

.markdown h5,
h6 {
  font-size: 1em; /* 16px --> 16 ÷ 16 = 1 */
  line-height: 1.5em; /* 24px --> 24 ÷ 16 = 1.5 */
}

.markdown p {
  margin-bottom: 24px;
}

/*--- LISTS ---*/
.markdown ul,
ol {
  margin-bottom: 24px;
  /* Remember, if your magic number is
    different to this, use your own. */
}

.markdown ul {
  list-style: square outside;
}

.markdown ul ul,
ol ol {
  margin: 0 0 0 60px;
}

/*--- QUOTES ---*/

.markdown blockquote {
  margin: 0 60px 0 45px;
  border-left: 5px solid #ccc;
  padding-left: 10px;
  text-indent: -0.4em;
}

.markdown blockquote b {
  display: block;
  text-indent: 0;
}

/*--- LINKS ---*/
.markdown a {
  color: #09f;
  text-decoration: none;
}

.markdown a:hover {
  text-decoration: underline;
}

.markdown a:active,
.markdown a:focus {
  position: relative;
  top: 1px;
}
